
















































































import { Vue,Component, Watch } from "vue-property-decorator";
import { FindImportacaoUseCase } from '@/usecases'
import AlertModule from "@/store/vuex/aplicacao/AlertModule";
import { ItemDeImportacao } from "@/models";
import { DataOptions } from "vuetify";
import axios, { CancelTokenSource } from 'axios'
import { sortConverter } from "@/shareds/paginacao";
import { nextTick } from "@/shareds/utils";

@Component({
	components: {
		
	},
})
export default class DialogoItensDeImportacao extends Vue{
	itens: ItemDeImportacao[] = []
	mostra = false
	importacaoId = ''
	findImportacaoUseCase = new FindImportacaoUseCase()
	origem = ''
	totalDeRegistros = -1
	cancelToken: CancelTokenSource | null = null
	buscando = false

	headers = [
		{ text: 'Item', value: 'item' },
	]

	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 10,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	get tamanhoDialogo() {
		if(this.origem == 'Tabela De Preço') return "1500"
		if(this.origem == 'Estoque') return "700"
		if(this.origem == 'Cliente') return "1600"
		if(this.origem == 'Cashback') return "1215"
		return "900"
	}

	get itensFormatados() { 
		if(this.origem == 'Tabela De Preço' || this.origem == 'Estoque' || this.origem == 'Cliente' || this.origem == 'Cashback') {
			const itensComErro = this.itens.map(item => {
				const itemParse = JSON.parse(item.item)
				return	{
					...itemParse,
					mensagemDeErro: item.mensagemDeErro,
				}
			})
			
			return itensComErro.map(item => { 
				if(item.mensagemDeErro != undefined) return item
				
				item.mensagemDeErro = 'Sem Mensagem'

				return item
			})
		}
		return this.itens
	}

	async mostrar(importacaoId: string, origem: string) {
		this.importacaoId = importacaoId
		this.origem = origem
		this.defineTabela() 
		this.buscarItensComErro() 
		this.mostra = true
	}
	
	async buscarItensComErro() {
		if (this.cancelToken) this.cancelToken.cancel()
		await nextTick()
		try {
			this.buscando = true 
			this.cancelToken = axios.CancelToken.source()
			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}
			const sorted = sortConverter(this.paginacao)
			const page = await this.findImportacaoUseCase.findItens({
				importacaoId: this.importacaoId,
				sort: [...sorted],
				page: this.paginacao.page - 1 || 0,
				size: this.paginacao.itemsPerPage,
			},
			axiosConfig) 
			this.itens = page.content
			this.totalDeRegistros = page.totalElements
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.buscando = false
		}
	}

	defineTabela() {
		if (this.origem == 'Tabela De Preço')
			this.headers = [
				{ text: 'SKU', value: 'sku' },
				{ text: 'Preço de Compra', value: 'precoCompra' },
				{ text: 'Margem de Lucro', value: 'margemLucro' },
				{ text: 'Desconto Varejo', value: 'descontoVarejo' },
				{ text: 'Preço Final', value: 'precoFinalVarejo'},
				{ text: 'Inicio Vigência', value: 'inicioVigencia'},
				{ text: 'Fim Vigência', value: 'fimVigencia'},
				{ text: 'Limitar Produto', value: 'limitarProduto'},
				{ text: 'Quantidade Limitado do Produto', value: 'qtdeLimitadaDoProduto'},
				{ text: 'Percentual de Cashback', value: 'percentualCashback'},
				{ text: 'Dias para Efetuar Cashback', value: 'diasParaEfetuarCashback'},
				{ text: 'Erro', value: 'mensagemDeErro'},
			]
		if(this.origem == 'Estoque')
			this.headers = [
				{ text: 'SKU', value: 'sku' },
				{ text: 'Quantidade no Estoque', value: 'qtdEstoque' },
				{ text: 'Quantidade Empenhada', value: 'qtdEmpenhada' },
				{ text: 'Erro', value: 'mensagemDeErro'},
			]
		if(this.origem == 'Cliente')
			this.headers = [
				{ text: 'Razão Social ou Nome', value: 'razaoSocialOuNome' },
				{ text: 'CNPJ ou CPF', value: 'cnpjOuCpf' },
				{ text: 'Data de Nascimento', value: 'dataDeNascimento' },
				{ text: 'Email', value: 'email' },
				{ text: 'Endereço', value: 'endereco' },
				{ text: 'Inscrição Estadual', value: 'inscricaoEstadual' },
				{ text: 'Telefone', value: 'telefones' },
				{ text: 'Tipo de Cliente', value: 'tipoDeCliente' },
				{ text: 'Genêro', value: 'genero'},
				{ text: 'Erro', value: 'mensagemDeErro'},
			]

		if(this.origem == 'Cashback')
			this.headers = [
				{ text: 'CNPJ da loja', value: 'cnpjDaLoja' },
				{ text: 'CPF/CNPJ do cliente', value: 'cpfOuCnpjDoCliente' },
				{ text: 'SKU/EAN', value: 'skuOuEan' },
				{ text: 'Cashback R$', value: 'valorDoCashback' },
				{ text: 'Percentual', value: 'percentualCashback' },
				{ text: 'Dias para efetivar', value: 'diasParaEfetuarCashback' },
				{ text: 'Dias para expirar', value: 'diasParaExpirarCashback' },
				{ text: 'Erro', value: 'mensagemDeErro'},
			]
	}

	@Watch('paginacao', { deep: true })
	onChangePaginacao = this.buscarItensComErro

}
