var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fit-content"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"label":"Selecione o tipo de processo...","items":['Exportação', 'Importação', 'Atualização'],"multiple":""},model:{value:(_vm.filtro.tipoDeProcesso),callback:function ($$v) {_vm.$set(_vm.filtro, "tipoDeProcesso", $$v)},expression:"filtro.tipoDeProcesso"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"label":"Selecione o tipo...","items":['Tabela De Preço', 'Estoque', 'Cliente', 'Produto', 'Cashback', 'Atualizar Data de Expiração'],"multiple":""},model:{value:(_vm.filtro.origem),callback:function ($$v) {_vm.$set(_vm.filtro, "origem", $$v)},expression:"filtro.origem"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"label":"Selecione o status...","items":['Incompleto','Pendente','Em Andamento', 'Concluído'],"multiple":"","value":"Incompleto"},model:{value:(_vm.filtro.status),callback:function ($$v) {_vm.$set(_vm.filtro, "status", $$v)},expression:"filtro.status"}})],1),_c('v-col',{attrs:{"cols":"12","sm":""}},[_c('RangeDatePicker',{attrs:{"inicial":{
								label: 'Data Inicial',
								clearable: true,
							},"final":{
								label: 'Data Final',
								clearable: true,
							}},model:{value:(_vm.filtro.datas),callback:function ($$v) {_vm.$set(_vm.filtro, "datas", $$v)},expression:"filtro.datas"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"color":"primary","filled":"","loading":_vm.buscando,"disabled":_vm.buscando},on:{"click":_vm.buscar}},[_c('v-icon',[_vm._v("mdi-magnify")]),_vm._v(" Buscar ")],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"items":_vm.itensFormatados,"loading":_vm.buscando,"headers":_vm.headers,"options":_vm.paginacao,"server-items-length":_vm.totalDeRegistros},on:{"update:options":function($event){_vm.paginacao=$event}},scopedSlots:_vm._u([{key:"item.tipoDeProcesso",fn:function(ref){
							var item = ref.item;
return [_c('span',{class:_vm.statusColor(item.tipoDeProcesso)},[_vm._v(" "+_vm._s(item.tipoDeProcesso)+" ")])]}},{key:"item.status",fn:function(ref){
							var item = ref.item;
return [_c('span',{class:_vm.statusColor(item.status)},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.actions",fn:function(ref){
							var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var on = ref.on;
return [(item.status == 'Incompleto')?_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function () { return _vm.dialogoImportacao.mostrar(item.id, item.origem); }}},on),[_vm._v(" mdi-eye ")]):_vm._e()]}}],null,true)},[_vm._v(" Detalhar erros ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var on = ref.on;
return [_c('v-icon',_vm._g({staticStyle:{"cursor":"pointer"},attrs:{"small":""},on:{"click":function($event){return _vm.irPara(item.origem, item.idOrigem)}}},on),[_vm._v(" mdi-link-variant ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.returnMessage(item.origem))+" ")])]}}],null,true)})],1)],1),_c('DialogoItensDeImportacao',{ref:"dialogoImportacao"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }